<template>
  <div class="wrapper">
    <div class="left-side">
      <div class="left-side--child cBlack">
        <img
            src="../../../public/assets/icon/alloopharma-white.svg"
            alt="alloopharma"
            width="120"
        />
        <ion-text class="ion-no-margin ion-no-padding" style="margin: 10px 0 0 -10px ">
          <h1 class="titleFont">
            Je recrute
            <span class="cWhite">chez Alloopharma</span>
            <br/>
            les meilleurs profils
            <span class="cWhite">pour ma pharmacie</span>
          </h1>
        </ion-text>

        <h3 style="margin-top: 40px; font-size: 24px">Pharmaciens, étudiants et préparateurs en pharmacie</h3>

        <div class="stars-container">
          <p>★★★★★</p>
          <p style="font-size: 18px">Plus de 147 avis 5 étoiles</p>
        </div>

      </div>

      <div class="logo-container">
        <img
            v-for="(logo, index) in logos"
            :key="index"
            :src="getPartnerLogo(logo.name)"
            width="120"
            alt="partenaire"
        />
      </div>
    </div>
    <div class="right-side padding-on-desktop">
      <img
          src="../../../public/assets/icon/alloopharma-logo-blue.png"
          width="100"
          class="alloopharma-logo"
          alt="alloopharma en couleur"
      />
      <div v-if="isSignIn" class="left-on-desktop">
        <h1 class="title">Connectez-vous à votre compte</h1>
        <div class="flex-on-desktop">
          <ion-label color="dark" style="opacity: 0.6">
            Vous n'avez pas de compte ?
          </ion-label>
          <p class="ion-no-margin">
            <a @click="goSignUp" class="link">&nbsp;Inscrivez-vous</a>
          </p>
        </div>
      </div>
      <div v-else class="left-on-desktop">
        <h1 class="title">Inscrivez-vous</h1>
        <ion-label color="dark" style="opacity: 0.6">
          et obtenez vos premières missions !
        </ion-label>
        <p class="ion-no-margin">
          <a @click="goSignIn" class="link">J'ai déjà un compte</a>
        </p>
      </div>

      <div class="center">
        <ErrorDisplay @deleteErrorMsg="deleteErrorMsg" :errorMsg="errorMsg"/>
        <ion-input
            @ionFocus="scrollToInput($event)"
            color="dark"
            v-model="email"
            placeholder="Email"
        />
        <ion-input
            @ionFocus="scrollToInput($event)"
            color="dark"
            v-model="password"
            type="password"
            placeholder="Mot de passe"
        />
        <ion-input
            v-if="!isSignIn"
            @ionFocus="scrollToInput($event)"
            v-model="phone"
            placeholder="Téléphone"
            color="dark"
        />
        <div v-if="isSignIn">
          <ion-button
              expand="block"
              class="ion-margin-top sign-btn"
              @click="signIn"
          >
            Se connecter
          </ion-button>
          <div style="margin-top: 50px">
            <a class="link" @click="goResetPassword">
              Mot de passe oublié
            </a>
          </div>

        </div>
        <div v-if="!isSignIn">
          <ion-button
              expand="block"
              class="ion-margin-top sign-btn"
              @click="signUp"
          >
            S'inscrire
          </ion-button>
          <ion-label color="dark" class="left-on-desktop">
            <p class="bottom-on-desktop">En cliquant sur S'inscrire, vous acceptez les
              <a @click="openNewTabCGU" class="link link__light">Conditions Générales d'Utilisation</a>
              et notre
              <a @click="openNewTabConfidentialite" class="link link__light">Politique de confidentitalié</a>
            </p>
          </ion-label>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {Browser} from '@capacitor/browser';
import {IonButton, IonInput, IonLabel, IonText} from "@ionic/vue";
import {mapActions, mapGetters} from 'vuex'
import {signIn, signUp} from "@/services/api";
import router from '@/router'
import ErrorDisplay from "../ErrorDisplay";
import store from "@/store";
import {snakeToCamel} from "@/services/parser-snake-camel";

const logos = [
  {name: 'A.png'},
  {name: 'alphega.png'},
  {name: 'Elsie.png'},
  {name: 'evolupharm.png'},
  {name: 'hello-pharmacie.png'},
  {name: 'ipharm.png'},
  {name: 'leadersante.png'},
  {name: 'nepenthes.png'},
  {name: 'paris-pharma.png'},
  {name: 'pharma-best.png'},
  {name: 'pharmacie-lafayette.jpeg'},
  {name: 'pharmacie-reference-groupe.png'},
  {name: 'pharmactive.png'},
  {name: 'pharmavance.png'},
  {name: 'pharmavie.png'},
  {name: 'sunipharma.png'},
  {name: 'synergiephar.png'},
  {name: 'totum.png'},
  {name: 'well-well.png'}
]

export default {
  name: "Sign",
  components: {
    IonInput,
    IonLabel,
    IonText,
    IonButton,
    ErrorDisplay,
  },
  props: {
    modeSign: {type: String, required: true},
    emailStore: {type: String, required: false},
    sponsorCode: {type: String, required: false},
  },
  watch: {
    emailStore: function (newVal) {
      this.email = newVal
    }
  },
  data() {
    return {
      phone: "",
      email: this.signEmail,
      password: "",
      errorMsg: [],
      linkCgu: "https://api.alloopharma.co/api/v1/constants/cgu",
      linkConfidentialite: "https://api.alloopharma.co/api/v1/constants/policy",
      isSignIn: this.modeSign === 'SignIn',
      logos,
    }
  },
  computed: {
    ...mapGetters([
      'signEmail'
    ]),
  },

  methods: {
    ...mapActions([
      'setHeaders', 'setUser', 'setEmailUser'
    ]),
    scrollToInput(event) {
      event.srcElement.scrollIntoView({
        behavior: 'smooth'
      });
    },

      async openNewTabCGU() {
        await Browser.open({url: this.linkCgu})
      },
    async openNewTabConfidentialite() {
      await Browser.open({url: this.linkConfidentialite})
    },

    goSignUp() {
      this.setEmailUser(this.email);
      router.push("/inscription")
    },
    goSignIn() {
      this.setEmailUser(this.email);
      router.push("/connexion")
    },
    goResetPassword() {
      router.push("/reinitialisation-mot-de-passe")
    },

    deleteErrorMsg(msg) {
      const index = this.errorMsg.indexOf(msg);
      this.errorMsg.splice(index, index + 1);
    },

    async signIn() {
      this.errorMsg = [];
      const email = this.email;
      const password = this.password;
      signIn(email, password).then((response) => {
        const data = snakeToCamel(response.data);
        if (data.firstName && data.lastName && data.email && data.phone) {
          store.dispatch("setUser", data);
        }
        router.push("/tableau-bord");
      }).catch(() => {
        console.log('c"est ici')
        this.errorMsg = ["Compte Introuvable"]
      });
    },

    async signUp() {
      this.errorMsg = [];
      const email = this.email;
      const password = this.password;
      const phone = this.phone;
      const sponsorCode = this.sponsorCode;
      signUp(email, password, phone, sponsorCode).then(() => {
        router.push("/confirmation-telephone");
      }).catch((e) => {
        this.errorMsg = e.response.data.errors.full_messages
      })
    },
    getPartnerLogo(name) {
      return require(`/public/assets/icon/partners/${name}`)
    }
  },
}
</script>

<style scoped>

.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: var(--new-blue);
  /* background-image: radial-gradient(circle at 0% 0%, #FE66A2 0%, #fff0, transparent), */
 
  /* radial-gradient(circle at 0% 20%, #f9a155 20%, #fff0, transparent),
  radial-gradient(circle at 0% 70%, #bd8df9 50%, #fff0, transparent),
  radial-gradient(circle at 100% 0%, #62dafb 70%, #17bcea); */
}

.left-side {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-side--child {
  padding: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'ProximaNovaRegular';
  font-size: 24px;
}

.right-side {
  min-width: 400px;
  width: 40%;
  border-radius: 60px 0 0 60px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleFont {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'GoboldLowplusItalic';
  text-align: left;
  font-size: 42px;
}

.stars-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 60px;
}

.stars-container > p {
  padding: 0;
  margin: 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: white;
  padding: 10px 0 10px 0;
}

.alloopharma-logo {
  position: absolute;
  top: 50px;
  right: 50px;
}

.left-on-desktop {
  margin-bottom: 3rem;
  text-align: left;
}

.flex-on-desktop {
  display: flex;
}

.padding-on-desktop {
  padding: 0 2rem 0 2rem;
}

ion-input {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.4) !important;
  width: 100%;
  max-width: 400px;
  border-width: 1px;
  text-align: left;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  margin-bottom: 10px;
  padding: .1rem 1rem .1rem 1rem !important;
}

ion-button {
  text-transform: none;
  height: 2.6rem;
  width: 100%;
  max-width: 400px;
  background-color: transparent;
  --background: var(--new-blue);
  --background-hover: #9ce0be;
  --background-activated: #88f4be;
  --background-focused: #88f4be;
  transition: 200ms;
  border-radius: 50px;
}

.bottom-on-desktop {
  margin-top: 100px;
}

@media screen and (max-device-width: 960px), screen and (max-width: 960px) {
  .right-side {
    border-radius: 0;
    margin-top: 5vh;
    display: block;
    min-width: 100%;
  }

  .left-on-desktop {
    text-align: center;
  }

  .left-side {
    display: none;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    background-image: none;
  }

  .alloopharma-logo {
    position: unset;
  }

  .flex-on-desktop {
    display: block;
  }

  ion-input, ion-button, .bottom-on-desktop {
    max-width: 500px;
    margin: 1rem auto 1rem auto;
  }

  .bottom-on-desktop {
    margin-top: 25px;
  }

  .padding-on-desktop {
    padding: 0;
  }
}

* {
  white-space: pre-wrap;
  transition: 500ms;
}

ion-label {
  font-family: 'SFProTextMedium';
}

.title {
  font-family: 'SFProTextBold';
  font-size: 26px;
  margin-bottom: 20px;
}

ion-button:hover {
  translate: 0 -2px;
  transition: 200ms;
  box-shadow: 0 0.5px 2.1px -5px rgba(0, 0, 0, 0.015),
  0 1.1px 4.6px -5px rgba(0, 0, 0, 0.022),
  0 1.9px 7.6px -5px rgba(0, 0, 0, 0.027),
  0 2.9px 11.6px -5px rgba(0, 0, 0, 0.031),
  0 4.1px 16.7px -5px rgba(0, 0, 0, 0.035),
  0 5.8px 23.6px -5px rgba(0, 0, 0, 0.039),
  0 8.3px 33.4px -5px rgba(0, 0, 0, 0.043),
  0 12px 48.5px -5px rgba(0, 0, 0, 0.048),
  0 18.6px 74.8px -5px rgba(0, 0, 0, 0.055),
  0 33px 133px -5px rgba(0, 0, 0, 0.07);
}

</style>
